import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

import es6_bp_EN from './locales/en.es6_bp.json'
import es6_bp_se_EN from './locales/en.es6_bp_se.json'
import es6_bp_RU from './locales/ru.es6_bp.json'
import es6_bp_se_RU from './locales/ru.es6_bp_se.json'

// the translations
const resources = {
  en: {
    es6_bp: es6_bp_EN,
    es6_bp_se: es6_bp_se_EN
  },
  de: {
    es6_bp: es6_bp_EN,
    es6_bp_se: es6_bp_se_EN
  }
  , ru: {
    es6_bp: es6_bp_RU,
    es6_bp_se: es6_bp_se_RU
  }
}

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    debug: false,
    ns: ['es6_bp', 'es6_bp_se'],
    fallbackLng: 'ru',
    lowerCaseLng: true,
    load: 'languageOnly',
    /*lng: 'en',*/
    whitelist: ['en', 'ru', 'de', 'fr', 'it'],
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      // wait: true,
      useSuspense: false,
    },
  })

export default i18n

export const dangerHtml = (html) => {
  return { __html: html }
}

export const i18ntDangerHtml = (key, params) => {
  return { __html: i18n.t(key, params) }
}

export const i18nCurrentLanguage = () => i18n.language !== undefined ? i18n.language.split('-')[0] : undefined
