import React, { useState } from 'react'
import {
  DropdownItem,
  DropdownMenu,
  Dropdown,
  DropdownToggle
} from 'reactstrap'
import classNames from 'classnames'
import { mapToCssModules } from 'reactstrap/src/utils'

export const LanguageSelector = ({
  className,
  cssModule,
  Items = [],
  CurrentLanguage = 'en',
  onSelect
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const classes = mapToCssModules(
    classNames('lang-selector', className),
    cssModule
  )

  const toggle = () => setDropdownOpen((prevState) => !prevState)

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className={classes}>
      {Items.filter((item) => item.code === CurrentLanguage && item.active).map(
        (item) => (
          <DropdownToggle tag={'div'} key={item.code} caret>
            <span className={'flag-icon ' + item.flag} /> {item.title}
          </DropdownToggle>
        )
      )}
      <DropdownMenu right>
        {Items.filter(
          (item) => item.code !== CurrentLanguage && item.active
        ).map((item) => (
          <DropdownItem
            tag={'div'}
            key={item.code}
            disabled={item.disable}
            onClick={() => onSelect && onSelect(item.code)}
          >
            <span className={'flag-icon ' + item.flag} /> {item.title}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}
