import React, { useEffect } from 'react'
import ym from 'react-yandex-metrika'

export const withTracker = (WrappedComponent, pageTrack, config) => {
  const { yandexMetrika } = config
  const trackPage = (page, yandexMetrika) => {
    if (yandexMetrika !== undefined) {
      ym('hit', page)
    }
  }

  return (props) => {
    const yandexMetrika = config && config['yandex-metrika']

    useEffect(
      () =>
        trackPage(
          pageTrack !== undefined ? pageTrack : props.location.pathname,
          yandexMetrika
        ),
      [props.location.pathname, yandexMetrika]
    )

    return <WrappedComponent {...props} />
  }
}
